import "bindWithDelay/bindWithDelay"

/**
 * Simplified filterTable plugin
 * based on Sunny Walker <swalker@hawaii.edu> original code
 */
(function ($) {
    const finder = arg => el => $(el).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0
    $.expr.pseudos.filterTableFind = jQuery.expr.createPseudo(finder);

    $.fn.filterTable = function(options) {
        const defaults = {
            inputSelector: null,
            restricted: false,
            showAll: true
        }

        const settings = $.extend({}, defaults, options)

        const doFiltering = (table, q) => {
            const tbody = table.find('tbody');

            $('.vehicle-search').find('tr').hide();

            if (q === '') {
                if (settings.showAll) tbody.find('tr').show();

                return;
            }

            tbody.find('tr').hide();

            const tds = (settings.restricted)
                ? tbody.find('td.filter')
                : tbody.find('td')

            tds.filter(`:filterTableFind("${q}")`).closest('tr').show();
        }

        return this.each(() => {
            const t = $(this)
            const filter = $(settings.inputSelector);
            const filterAction = () => doFiltering(t, filter.val())

            if (t[0].nodeName !== 'TABLE') return;

            filter.on('click paste', filterAction);
            filter.bindWithDelay('keyup', filterAction, 200);
        });
    };
})(jQuery);
